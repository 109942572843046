<template>
  <div class="activity" v-if="data">
    <BackBtn class="activity__back" />
    <div class="activity-inner--large">
      <ArticleHeader :data="data.header" />

      <div class="activity__content">
        <div v-if="data.body.materialsKids">
          <h3>Matériels Animés</h3>
          <ul>
            <li
              v-for="material in data.body.materialsKids"
              :key="`material-${material.label}`"
            >
              {{ material.label }}
            </li>
          </ul>
        </div>

        <div v-if="data.body.materialsAdults">
          <h3>Matériels Animateurs</h3>
          <ul>
            <li
              v-for="material in data.body.materialsAdults"
              :key="`material-${material.label}`"
            >
              {{ material.label }}
            </li>
          </ul>
        </div>

        <template v-if="data.body.roles && Object.keys(data.body.roles).length">
          <h3>Rôles des animateurs</h3>
          <ul>
            <li v-for="role in data.body.roles" :key="`role-${role.label}`">
              {{ role.label }}
            </li>
          </ul>
        </template>

        <template v-if="data.body.preparation">
          <h3>Préparation du jeu</h3>
          <div
            class="activity__content__text"
            v-html="data.body.preparation"
          ></div>
        </template>

        <template v-if="data.body.objectives">
          <h3>Objectif principal et fin du jeu</h3>
          <div
            class="activity__content__text"
            v-html="data.body.objectives"
          ></div>
        </template>

        <template v-if="data.body.progress">
          <h3>Déroulement du jeu</h3>
          <div
            class="activity__content__text"
            v-html="data.body.progress"
          ></div>
        </template>

        <template v-if="data.body.variant">
          <h3>Description de la variante</h3>
          <div class="activity__content__text" v-html="data.body.variant"></div>
        </template>
      </div>

      <ArticleFooter
        :data="data.footer"
        pdfLabel="Télécharger l'activité en PDF"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/activity'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import ArticleFooter from '@/components/article/Footer.vue'
import ArticleHeader from '@/components/article/Header.vue'

export default defineComponent({
  name: 'Activity',
  components: {
    BackBtn,
    ArticleFooter,
    ArticleHeader,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.activity {
  @include details-intro;

  position: relative;
  background-color: $c-white;
}

[class*='activity-inner--'],
.activity-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  @include mq(s) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='activity__content--'],
.activity__content {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}

.activity__content h3 {
  margin-top: $spacing * 1.5;
  margin-bottom: $spacing / 4;

  &:first-child {
    margin-top: 0;
  }
}

.activity__content div {
  margin: 0 0 $spacing * 1.5;
}

.activity__content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing * 1.5;
}

.activity__content li {
  display: flex;

  & + li {
    margin-top: $spacing / 2;
  }
}

.activity__content li::before {
  content: '\2022';
  color: $regal-blue;
  display: inline-block;
  width: 6px;
  margin-right: $spacing / 2;
}

.activity__content__text {
  ::v-deep(p) {
    margin-bottom: $spacing / 2;
  }
}

.activity__back {
  .branch-horizon &,
  .branch-route & {
    color: white;
  }
}
</style>
